@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://rsms.me/inter/inter.css"); */
@import url("https://fonts.googleapis.com/css?family=Montserrat");
html {
  font-family: "Montserrat", sans-serif;
}
/* @supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
} */

/* @media (prefers-reduced-motion: no-preference) {

}
 */
input:disabled,
textarea:disabled {
  -webkit-text-fill-color: black; /* Override iOS / Android font color change */
  -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  opacity: 1; /* Override iOS opacity change affecting text & background color */
  color: black; /* Override IE font color change */
}

:root {
  --brand-white: #ffffff;
  --brand-orange: #ffa93d;
  --brand-orange-faded: #ffa93d80;
  --text-primary: #000000;

  --bg-base-layer: #f7f7f7;
  --bg-primary: var(--brand-white);
  --brand-light-blue: #72cbd6;
  --palette-green-dark: #103236;
  --palette-green-light: #314a4e;
  --focus-main: #44d1e4;

  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

:root,
code,
kbd,
samp,
pre {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  display: flex;
  overflow: hidden;
  /* -webkit-overflow-scrolling: touch; */
}

body,
#root {
  height: 100%;
  height: 50vh;
  height: calc(var(--vh, 1vh) * 100);
  /* height: fill-available; */
  /* 
   height: -webkit-fill-available;
   height: -moz-available; */
}

_::-webkit-full-page-media,
_:future,
:root #root,
:root body {
  /*  height: fill-available; */
  height: stretch;
}

#root {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  width: 100%;
  width: 100vw;
  color: var(--text-primary);
}

@media screen and (min-width: 1024px) {
  #root {
    grid-template-columns: 1fr auto;
    background: var(--bg-base-layer);
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2.2rem;
}
h3 {
  font-size: 1.9rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.3rem;
}
h6 {
  font-size: 1rem;
}

dialog::backdrop {
  background: #ffffffbb;
  animation: showDialog 0.5s ease normal;
}

dialog {
  background: transparent;
}

dialog[open] {
  animation: showDialog 0.5s ease normal;
}
@keyframes showDialog {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 
  Gradients copied from AdobeXD
*/
/* .sign-in-gradient {
  background: linear-gradient(270deg, #324b4f00, #324b4f);
}
 */
